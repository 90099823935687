<template>
  <a-modal
    class="dialog-refuse"
    v-model="visible"
    title="售后维权处理"
    width="840px"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    :afterClose="handleAfterClose"
    :okText="confirmBtnText"
    @ok="handleOk"
  >
    <a-alert :message="alertMessage" type="warning" show-icon />
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-model-item label="售后方式">{{serviceDetail.returnMethod === 1 ? '仅退款' : '退货退款'}}</a-form-model-item>
      <a-form-model-item label="退货件数">{{serviceDetail.returnCount}}</a-form-model-item>
      <a-form-model-item v-if="isBatch" label="退款金额"><span class="amount">¥ {{NP.plus(+(serviceDetail.returnAmount || 0), +(serviceDetail.returnLogisticsAmount || 0))}}</span>(含运费¥ {{serviceDetail.returnLogisticsAmount}})</a-form-model-item>
      <a-form-model-item v-else label="退款金额"><span class="amount">¥ {{serviceDetail.returnAmount}}</span></a-form-model-item>
      <a-form-model-item v-if="serviceDetail.returnState === 3" label="退货地址">{{serviceDetail.receiverProvince}} {{serviceDetail.receiverCity}} {{serviceDetail.receiverCounty}} {{serviceDetail.receiverAddr}} {{serviceDetail.receiverName}} {{serviceDetail.receiverPhone}}</a-form-model-item>
      <a-form-model-item
        v-if="operationType === 'refuse'"
        label="拒绝原因"
        prop="cause"
      >
        <a-input
          type="textarea"
          v-model="form.cause"
          placeholder="请填写你的拒绝理由，不超过200个字"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="serviceDetail.returnState === 0 && operationType === 'agree' && serviceDetail.returnMethod === 2"
        prop="shopAddrId"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
      >
        <p class="addr-label">退货地址：<a-button v-if="currentShopId === serviceDetail.supplyShopId" type="link" @click="handleAddShopAddr">新增地址</a-button><a-button type="link" @click="getReturnAddress">刷新</a-button></p>
        <a-spin :spinning="returnAddrLoading">
          <template v-if="returnAddress.length">
            <a-radio-group class="addr-radio-group" v-model="form.shopAddrId">
              <a-radio
                v-for="item in returnAddress"
                :key="item.shopAddrId"
                :style="{
                  display: 'block',
                  marginLeft: '28px',
                  marginBottom: '3px',
                  paddingLeft: '12px',
                  lineHeight: '36px',
                  backgroundColor: '#F3F6F8'
                }"
                :value="item.shopAddrId"
              >
                {{item.province}}{{item.city}}{{item.county}} {{item.addr}}
              </a-radio>
            </a-radio-group>
            <div class="custom-pagination">
              <a-pagination
                v-model="pagination.current"
                size="small"
                :page-size="pagination.size"
                :total="pagination.total"
                :show-total="total => `共 ${total} 条`"
              />
            </div>
          </template>
          <a-empty
            v-else
            :imageStyle="{ height: '70px' }"
            :description="currentShopId === serviceDetail.supplyShopId ? '请维护退货地址' : '请联系供应商维护退货地址'"
          />
        </a-spin>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { order } from '@/api'
import { computed, ref, watch } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import NP from 'number-precision'

export default {
  name: 'DialogServiceFeedback',

  props: {
    show: Boolean,
    isBatch: Boolean,
    serviceDetail: Object,
    operationType: String
  },

  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('update:show', val)
      }
    })
    const currentShopId = computed(() => getSession(SHOPID))
    const isAgree = computed(() => props.operationType === 'agree')
    const confirmBtnText = computed(() => {
      const { serviceDetail } = props
      if (serviceDetail.returnState === 0) {
        if (!isAgree.value) return '拒绝退款'
        if (isAgree.value) return serviceDetail.returnMethod === 2 ? '同意并发送退货地址' : '同意退款'
      } else if (serviceDetail.returnState === 3) {
        if (!isAgree.value) return '未收到货，拒绝退款'
        if (isAgree.value) return '确认收到退货'
      }
    })
    const alertMessage = computed(() => {
      const { serviceDetail } = props
      if (!isAgree.value) return '建议你与买家协商后，再决定是否拒绝退款。如你拒绝退款后，买家可重新提交退款申请。'
      if ((isAgree.value && serviceDetail.returnMethod === 2) || serviceDetail.returnState === 3) return '需要你同意退款申请，买家才能退货给你；买家退货后你需再次确认收货后，退款将自动原路退回至买家付款账户。'
      if (serviceDetail.returnState === 0 && isAgree.value) return '同意退款后，退款将自动原路退回至买家付款账户。'
    })

    // 获取退货地址
    const returnAddress = ref([])
    const returnAddrLoading = ref(false)
    const pagination = ref({
      current: 1,
      size: 10,
      total: 0
    })
    watch(() => visible.value, data => {
      if (data && isAgree.value && props.serviceDetail.returnMethod === 2 && !returnAddress.value.length) {
        getReturnAddress()
      }
    })
    async function getReturnAddress () {
      returnAddrLoading.value = true
      const { data, page } = await order.getReturnAddrByType({
        ...pagination.value,
        shopId: props.isBatch ? getSession(SHOPID) : props.serviceDetail.supplyShopId
      })
      returnAddrLoading.value = false
      pagination.value.total = page.total
      returnAddress.value = data || []
      form.value.shopAddrId = data && data.length ? data[0].shopAddrId : ''
    }

    const formRef = ref(null)
    const form = ref({
      cause: '',
      shopAddrId: ''
    })
    const rules = ref({
      cause: [
        { required: true, message: '请输入' },
        { max: 200, message: '请输入简短一些' }
      ]
    })
    const confirmLoading = ref(false)
    function handleAfterClose () {
      form.value.cause = ''
    }

    function handleOk () {
      const { serviceDetail } = props
      if (!serviceDetail || (props.isBatch && !serviceDetail.orderWholesaleReturnId) || (!props.isBatch && !serviceDetail.orderReturnApplyId)) return root.$message.warn('未获取到必要参数')
      if (isAgree.value && !form.value.shopAddrId && serviceDetail.returnState === 0 && serviceDetail.returnMethod === 2) return root.$message.warn('请选择退货地址')
      formRef.value.validate(async valid => {
        if (!valid) return
        confirmLoading.value = true
        let data = {}
        if (props.operationType === 'refuse') {
          data = await (props.isBatch ? order.batchOrderReturnUpdate({
            orderWholesaleReturnId: props.serviceDetail.orderWholesaleReturnId,
            refusalCause: form.value.cause,
            returnState: -1
          }) : order.shopOrderReturnRefuse({
            orderReturnApplyId: props.serviceDetail.orderReturnApplyId,
            cause: form.value.cause
          }))
        } else if (props.operationType === 'agree') {
          if (serviceDetail.returnState === 3) {
            data = await (props.isBatch ? order.batchOrderReturnUpdate({
              orderWholesaleReturnId: props.serviceDetail.orderWholesaleReturnId,
              refusalCause: form.value.cause,
              returnState: 1
            }) : order.shopOrderReturnReceived(serviceDetail.orderReturnApplyId))
          } else {
            data = await (props.isBatch ? order.batchOrderReturnUpdate({
              orderWholesaleReturnId: props.serviceDetail.orderWholesaleReturnId,
              refusalCause: form.value.cause,
              returnState: props.serviceDetail.returnMethod === 1 ? 1 : 2,
              shopAddrId: form.value.shopAddrId
            }) : order.shopOrderReturnPass({
              orderReturnApplyId: serviceDetail.orderReturnApplyId,
              shopAddrId: form.value.shopAddrId
            }))
          }
        }
        confirmLoading.value = false
        if (data.code === '00000') {
          root.$message.success('提交成功')
          visible.value = false
          emit('success')
        } else {
          root.$message.error(data.msg || '提交失败，请稍后重试')
        }
      })
    }

    function handleAddShopAddr () {
      visible.value = false
      root.$router.push('/shop/address')
    }

    return {
      visible,
      currentShopId,
      confirmBtnText,
      alertMessage,
      confirmLoading,
      handleAfterClose,
      handleOk,
      returnAddress,
      returnAddrLoading,
      pagination,
      getReturnAddress,
      formRef,
      form,
      rules,
      handleAddShopAddr,
      NP,
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-refuse {
  .amount {
    color: @warning-color;
  }
  ::v-deep .ant-form {
    padding-top: 12px;
  }
  ::v-deep .ant-form-item-label,
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  ::v-deep .ant-form-item-required::before {
    display: none;
  }
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
  ::v-deep .addr-radio-group {
    width: 100%;
  }
  .custom-pagination {
    padding: 16px 0;
    text-align: right;
  }
  .addr-label {
    padding-left: 28px;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .ant-btn {
      padding: 0 6px;
    }
  }
}
</style>
